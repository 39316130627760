.pageComponentMainContainer {
	width: 100%;
	height: 100vh;

/*	margin: 0px 0px 20px 0px;*/

	.pageComponentContainer {
		width: 100%;
/*		height: 82.8vh;*/
/*		height: 81.8vh;*/
		height: 78.8vh;
		overflow: auto;
/*		padding-top: 10px;*/
		padding: 20px 0px;

		.pageContent {
			width: 97%;
			/*	        height: 100%;*/
			margin: 0px auto;
		}
	}

	.scrollToTopBtn {
		/*position: fixed;
        bottom: 8vh;
        right: 2vh;*/
		position: fixed;
		bottom: 9vh;
		right: 2vh;
		display: none;
		z-index: 1000;

		background: #f06548 !important;
		min-width: unset;
		min-height: unset;
		padding: 13px 12px 11px 12px;
		margin: 0px;
		line-height: 1;
		border: none !important;

		animation-duration: 3s;
		/*    	animation-iteration-count: infinite;*/
		animation-iteration-count: 1;

		.scrollToTopBtnIcon {
			width: 15px;
			height: 15px;
			fill: #FFFFFF;
		}

		&:hover {
			animation-name: bounce;
			animation-timing-function: ease;
		}
	}
}

@keyframes bounce {
	0% {
		transform: translateY(0);
		opacity: 1;
	}

	50% {
		transform: translateY(-30px);
		opacity: 0.5;
	}

	100% {
		transform: translateY(0);
		opacity: 1;
	}
}