/*.loaderContainer {
	position: absolute;
	z-index: 99999;
	width: 100%;
	height: 100vh;
	top: 0;
	background: rgb(0, 0, 0, 0.4);

	img,
	.loaderIcon {
		position: absolute;
		top: 0;
		bottom: 0;
		left: 0;
		right: 0;
		margin: auto;
	}

	.loaderIcon {
		fill: #17b2a6;
		color: #17b2a6;
	}
}*/

.loaderContainer {
	position: absolute;
    z-index: 99999;
    width: 100%;
    height: 100vh;
    top: 0;
    opacity: 40%;
/*    background: #0006;*/
    background: black;
    display: flex;
    justify-content: center;

    img,
    .loaderIcon {
        position: relative;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        margin: auto;
    }

    .loaderIcon {
        fill: #17b2a6;
        color: #17b2a6;
    }
}