@import url('https://fonts.googleapis.com/css?family=Inter:300,400,500,600,700');
@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;500;600;700&display=swap");

.App {
    background-color: #FFFFFF !important;
    z-index: 2;
    opacity: 1;
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-size: cover;
/*    height: 100vh;*/
    height: 98vh;
    width: 100vw;
    font-style: normal !important;
    font-family: Inter, sans-serif !important;
    overflow-x: auto;
    overflow-y: hidden;
}

body, html {
    font-family: Inter, sans-serif !important;
    font-style: normal !important;
    margin: 0px;
    padding: 0px;
}

/*.pageComponentContainer {
    width: 100%;
    min-height: 71vh;
    height: 71vh;
    overflow-y: auto;
    overflow-x: hidden;
    color: #495057;
    padding: 20px 0px;

    .pageContent {
        width: 87.5%;
        height: 100%;
        margin: 0px auto;
    }
}*/

.App.darkMode {
    background-color: #1a1d21 !important;
    
    .pageComponentContainer {
        color: #FFFFFF !important;
    }
}

body.darkMode {
    background-color: #1a1d21 !important;
}


::-webkit-scrollbar {
  width: 10px !important;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: none !important;
  border-radius: 10px !important;
}
 
/* Handle */
::-webkit-scrollbar-thumb {
  background: transparent !important;
  border-radius: 10px !important;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #80808080 !important;
}



@media only screen and (max-width: 991px) {

}